import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import useCartStore from 'stores/cartStore';

function PaymentSuccessful({order}) {
  const {t} = useTranslation();
  const clearCart = useCartStore(state => state.clearCart);
  useEffect(()=>{
    clearCart()
  },[])
  return (
    <div className="container">
        <h1 >{t('thankyoutitle')} {order.first_name}!</h1>
    </div>
  )
}

export default PaymentSuccessful
